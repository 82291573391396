import { type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const availabilityRoutes: RouteObject = {
  path: 'availability',
  errorElement: <ErrorBoundary />,
  async lazy() {
    const { Availability, loader, action } = await import('./availability')
    return { Component: Availability, loader, action }
  },
  // children: [],
}
