import { cloneElement } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material'
import { useGlobalState } from '@trinity/components'

type SupplierTableProps<T> = {
  headers: string[]
  data: T[]
  children: (item: T) => JSX.Element
} & (
  | { selectedItems: T[]; setSelectedItems: (items: T[]) => void }
  | { selectedItems?: undefined; setSelectedItems?: undefined }
)

export function SupplierTable<T>({ headers, data, selectedItems, setSelectedItems, children }: SupplierTableProps<T>) {
  const { onMobile } = useGlobalState()
  const isSelected = (item: T) => selectedItems?.includes(item)

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              '& > :first-of-type': { pl: 8 },
              [`& > .${tableCellClasses.head}`]: { bgcolor: 'common.black', color: 'common.white', py: 0.75 },
            }}
          >
            {headers.map(header => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length < 1 && (
            <TableRow>
              <TableCell colSpan={headers.length} sx={{ typography: 'h6' }} align='center'>
                No Records Found
              </TableCell>
            </TableRow>
          )}
          {data.map((item, index) => (
            <TableRow
              key={index}
              onClick={() => {
                if (!selectedItems || onMobile) return
                if (isSelected(item)) {
                  setSelectedItems(selectedItems.filter(i => i !== item))
                } else {
                  setSelectedItems([...selectedItems, item])
                }
              }}
              sx={{
                bgcolor: isSelected(item) ? 'primary.extraLight' : null,
                border: isSelected(item) ? 'selected' : null,
                '&:nth-of-type(even)': {
                  bgcolor: isSelected(item) ? 'primary.extraLight' : 'grey.50',
                },
                '& > :first-of-type': { pl: 8 },
                [`& > .${tableCellClasses.body}`]: { py: 2 },
                '&:hover': selectedItems
                  ? { bgcolor: 'primary.extraLight', cursor: 'pointer', border: 'selected' }
                  : {},
              }}
            >
              {cloneElement(children(item))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
