import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const resourceRoutes: RouteObject = {
  path: 'resources',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: <Navigate to='/' />,
    },
    {
      path: 'fabric-orders.csv',
      async lazy() {
        const { loader } = await import('./fabric-orders.csv')
        return { loader }
      },
    },
    {
      path: 'availability.csv',
      async lazy() {
        const { loader } = await import('./availability.csv')
        return { loader }
      },
    },
  ],
}
