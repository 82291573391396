import { createSearchParams, redirect } from 'react-router-dom'
import { IN_STOCK, UNAVAILABLE, addFabricOrderToShipment, updateFabricAvailability } from '@trinity/utils'
import { ADD_TO_SHIPMENT, PRINT_LABEL, SHOW_AVAILABILITY_DIALOG, UPDATE_AVAILABILITY } from './constants'

export async function handleFabricOrderAction(params: Record<string, FormDataEntryValue>) {
  switch (params.action) {
    case PRINT_LABEL: {
      if (!params.id) {
        return { error: true, message: 'You must select at least one fabric order to print' }
      }
      const fabricOrderIds = { fabricOrderId: params.id.toString().split(',') }
      throw redirect(`/fabric-orders/label?${createSearchParams(fabricOrderIds).toString()}`)
    }
    case ADD_TO_SHIPMENT: {
      if (!params.id) {
        return { error: true, message: 'You must select at least one fabric order to add to a shipment' }
      }
      const fabricOrderIds = params.id.toString().split(',')
      const response = await addFabricOrderToShipment({ fabricOrderIds })
      return { type: params.action, ...response } as const
    }
    case SHOW_AVAILABILITY_DIALOG: {
      return {
        type: params.action,
        supplierFabricNumber: params.supplierFabricNumber,
        currentStatus: params.currentStatus,
      } as const
    }
    case UPDATE_AVAILABILITY: {
      const updateParams = {
        ...params,
        inStock: params.status === IN_STOCK ? 1 : 0,
        supplierEnabled: params.status === UNAVAILABLE ? 0 : 1,
      }
      const response = await updateFabricAvailability(updateParams)
      return { type: params.action, ...response } as const
    }
    default: {
      throw new Error('Invalid action')
    }
  }
}
