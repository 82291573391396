import React from 'react'
import { createRoot } from 'react-dom/client'
import { initSentry } from '@trinity/utils'
import App from './App'

const sentryDsn = 'https://3a4dad958898f431008200b98e18d5ed@o170893.ingest.sentry.io/4506123353456640'
const sentryRelease = `supplier@${__APP_VERSION__}`
initSentry(sentryDsn, sentryRelease)

const rootEl = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootEl!)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
