import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'
import { FabricOrders, loader, action } from './fabric-orders'

export const fabricOrdersRoutes: RouteObject = {
  path: 'fabric-orders',
  id: 'fabricOrders',
  element: <FabricOrders />,
  loader,
  action,
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: <Navigate to='single-cut' />,
    },
    {
      path: 'single-cut',
      async lazy() {
        const { SingleCut, action } = await import('./fabric-orders.single-cut')
        return { Component: SingleCut, action }
      },
    },
    {
      path: 'bolt',
      async lazy() {
        const { Bolt, action } = await import('./fabric-orders.bolt')
        return { Component: Bolt, action }
      },
    },
    {
      path: 'label',
      async lazy() {
        const { Label, loader } = await import('./fabric-orders.label')
        return { Component: Label, loader }
      },
    },
  ],
}
