import { type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'
import { Shipments, loader } from './shipments'

export const shipmentsRoutes: RouteObject = {
  path: 'shipments',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: <Shipments />,
      loader: loader,
    },
    {
      path: ':id',
      children: [
        {
          index: true,
          async lazy() {
            const { ShipmentDetail, loader, action } = await import('./shipments.$id')
            return { Component: ShipmentDetail, loader, action }
          },
        },
        {
          path: 'label',
          async lazy() {
            const { Label, loader } = await import('./shipments.$id.label')
            return { Component: Label, loader }
          },
        },
      ],
    },
  ],
}
