import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const accountRoutes: RouteObject = {
  path: 'account',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: <Navigate to='manage' />,
    },
    {
      path: 'manage',
      children: [
        {
          index: true,
          async lazy() {
            const { Manage, action } = await import('./manage')
            return { Component: Manage, action }
          },
        },
      ],
    },
  ],
}
