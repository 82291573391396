import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Container,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  tooltipClasses,
  type TooltipProps,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material'
import { Assignment, FlipToBack, MoreVert, Person } from '@mui/icons-material'
import { useGlobalState } from '@trinity/components'
import { Logo } from 'assets'

export function NavBar() {
  const { onMobile, user } = useGlobalState()

  if (onMobile) return <MobileNav />

  return (
    <AppBar
      position='static'
      sx={{ bgcolor: 'common.white', boxShadow: theme => theme.elevation.divider, transform: 'none' }}
    >
      <Container maxWidth='lg'>
        <Toolbar disableGutters sx={{ height: { xs: 80, sm: 90 } }}>
          <Link to='/fabric-orders/single-cut'>
            <img src={Logo} alt='logo' width='169px' height='56px' />
          </Link>
          <Stack
            direction='row'
            spacing={4}
            sx={{ justifyContent: 'flex-end', flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
          >
            <StyledTooltip
              title={
                <Stack spacing={2} p={2}>
                  <StyledLink to='/fabric-orders/single-cut'>SINGLE CUT ORDERS</StyledLink>
                  <StyledLink to='/fabric-orders/bolt'>BOLT ORDERS</StyledLink>
                </Stack>
              }
            >
              <Typography>FABRIC ORDERS</Typography>
            </StyledTooltip>
            <StyledLink to='/shipments'>SHIPMENTS</StyledLink>
            <StyledLink to='/availability'>AVAILABILITY</StyledLink>
            <StyledTooltip
              title={
                <Stack spacing={2} p={2}>
                  <StyledLink to='/account/manage'>MANAGE</StyledLink>
                  <StyledLink to='/logout'>LOGOUT</StyledLink>
                </Stack>
              }
            >
              <Typography>{user.supplier?.name?.toUpperCase() ?? 'ACCOUNT'}</Typography>
            </StyledTooltip>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

function StyledTooltip(props: TooltipProps) {
  return (
    <Tooltip
      {...props}
      PopperProps={{ sx: { [`.${tooltipClasses.tooltip}`]: { bgcolor: 'common.white', boxShadow: 1 } } }}
      sx={{ cursor: 'pointer', typography: 'nav1', '&:hover': { color: 'grey.700' } }}
    />
  )
}

function StyledLink({ to, children }: { to: string; children: React.ReactNode }) {
  return (
    <Typography
      variant='nav1'
      component={Link}
      to={to}
      sx={{
        textDecoration: 'none',
        '&:hover': { color: 'grey.700' },
      }}
    >
      {children}
    </Typography>
  )
}

function MobileNav() {
  const location = useLocation()
  // grab the first 4 characters of the pathname to determine the base route
  const baseRoute = location.pathname.substring(1, 5)
  // find the index of the base route in the baseNavList
  const baseNavIndex = navItems.findIndex(item => item.nav.startsWith(baseRoute))
  const [value, setValue] = useState(baseNavIndex)
  const navigate = useNavigate()

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(_event, newValue: number) => {
        setValue(newValue)
        navigate(navItems[newValue]?.nav ?? navItems[0].nav)
      }}
      sx={{ position: 'fixed', bottom: 0, width: '100%', height: 75, boxShadow: 6, zIndex: 'tooltip' }}
    >
      {navItems.map(({ nav, icon }, index) => (
        <BottomNavigationAction
          key={index}
          label={nav.split('-').join(' ')}
          icon={icon}
          sx={{ textTransform: 'capitalize' }}
        />
      ))}
    </BottomNavigation>
  )
}

const navItems = [
  { nav: 'fabric-orders', icon: <Assignment /> },
  { nav: 'shipments', icon: <Person /> },
  { nav: 'availability', icon: <FlipToBack /> },
  { nav: 'logout', icon: <MoreVert /> },
] as const
