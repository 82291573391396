import { type ActionFunctionArgs, useActionData, Form } from 'react-router-dom'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { login, type UseActionData } from '@trinity/utils'
import { LoginImage, Logo } from 'assets'

export async function action({ request }: ActionFunctionArgs) {
  return await login(request, 'supplier', '/login')
}

export function Login() {
  const actionData = useActionData() as UseActionData<typeof action>

  return (
    <Stack direction='row'>
      <Box
        flex={1}
        sx={{ backgroundImage: `url(${LoginImage})`, backgroundRepeat: 'no-repeat', height: window.innerHeight }}
        display={{ xs: 'none', laptop: 'block' }}
      />
      <Stack spacing={{ mobile: 20, laptop: 40 }} flex={1} alignItems='center' pt={5}>
        <img src={Logo} alt='Trinity Apparel Logo' width='250px' />
        <Form method='POST'>
          <Stack spacing={4}>
            <Typography variant='h1'>Supplier Interface</Typography>
            <TextField required variant='filled' name='username' label='Username' />
            <TextField required variant='filled' type='password' name='password' label='Password' />
            {actionData?.error && (
              <Typography color='error' align='center'>
                {actionData.data}
              </Typography>
            )}
            <Button type='submit'>Log In</Button>
          </Stack>
        </Form>
      </Stack>
    </Stack>
  )
}
