//* FABRIC ORDER ACTIONS
export const PRINT_LABEL = 'printLabel'
export const ADD_TO_SHIPMENT = 'addToShipment'
export const SHOW_AVAILABILITY_DIALOG = 'showAvailabilityDialog'
export const UPDATE_AVAILABILITY = 'updateAvailability'

//* SHIPMENT ACTIONS
export const REMOVE_FABRIC_ORDER = 'removeFabricOrder'
export const DELETE_SHIPMENT = 'deleteShipment'
export const COMPLETE_SHIPMENT = 'completeShipment'
