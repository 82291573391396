import { Navigate, Outlet, createBrowserRouter, useLoaderData } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BaseTrinityWrapper, ErrorBoundary, GlobalContainer, TrinityWrapper } from '@trinity/components'
import { checkAuth, type UseLoaderData } from '@trinity/utils'
import { NavBar } from 'components'
import { fabricOrdersRoutes } from './fabric-orders'
import { availabilityRoutes } from './availability'
import { shipmentsRoutes } from './shipments'
import { Login, action as loginAction } from './login'
import { loader as logoutLoader } from './logout'
import { resourceRoutes } from './resources'
import { accountRoutes } from './account'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter(
  [
    {
      path: '/',
      element: <Layout />,
      loader,
      children: [
        { index: true, element: <Navigate replace to='/fabric-orders/single-cut' /> },
        resourceRoutes,
        fabricOrdersRoutes,
        availabilityRoutes,
        shipmentsRoutes,
        accountRoutes,
        {
          path: '*',
          element: <div>Page Not Found</div>,
        },
      ],
      errorElement: <ErrorBoundary />,
    },
    {
      path: 'login',
      element: (
        <BaseTrinityWrapper appName='supplier'>
          <Login />
        </BaseTrinityWrapper>
      ),
      action: loginAction,
      errorElement: <ErrorBoundary />,
    },
    {
      path: 'logout',
      loader: logoutLoader,
      errorElement: <ErrorBoundary />,
    },
  ],
  // { basename: '/app/supplier' },
)

async function loader() {
  const user = await checkAuth('supplier', '/login')

  return user
}

function Layout() {
  const user = useLoaderData() as UseLoaderData<typeof loader>

  return (
    <TrinityWrapper appName='supplier' user={user}>
      <nav>
        <NavBar />
      </nav>
      <main>
        <GlobalContainer sx={{ pb: { mobile: 12, laptop: 4 } }}>
          <Outlet />
        </GlobalContainer>
      </main>
    </TrinityWrapper>
  )
}
