import { useEffect, useState } from 'react'
import { useLoaderData, type LoaderFunctionArgs, useSearchParams, useNavigate, Link } from 'react-router-dom'
import { Button, Divider, Stack, TableCell, ToggleButton, Typography } from '@mui/material'
import { Pagination, ToggleButtonGroup, useGlobalState } from '@trinity/components'
import { type UseLoaderData, getShipments, parseSearchParams, Shipment } from '@trinity/utils'
import { SupplierTable } from 'components'

export async function loader({ request }: LoaderFunctionArgs) {
  const params = parseSearchParams(request)
  const data = await getShipments(params)
  return data
}

export function Shipments() {
  const { onMobile } = useGlobalState()
  const { shipments, totalShipments } = useLoaderData() as UseLoaderData<typeof loader>
  const [params, setParams] = useSearchParams()
  const navigate = useNavigate()
  const [shipStatus, setShipStatus] = useState(params.get('status') ?? Shipment.Status.PENDING)
  const isPending = shipStatus === Shipment.Status.PENDING

  useEffect(() => {
    const currentStatus = params.get('status') ?? Shipment.Status.PENDING
    if (currentStatus !== shipStatus) {
      setShipStatus(currentStatus)
    }
  }, [params, shipStatus])

  return (
    <Stack spacing={6} pt={{ mobile: 10, laptop: 4 }}>
      <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={4} justifyContent={{ laptop: 'space-between' }}>
        <Typography variant='h4'>Shipments</Typography>
        {onMobile && <Divider />}
        <ToggleButtonGroup
          value={shipStatus}
          onChange={(_e, v) => {
            if (v) {
              setShipStatus(v)
              setParams(params => {
                params.set('status', v)
                return params
              })
            }
          }}
          sx={{ width: 'fit-content', alignSelf: 'center' }}
        >
          <ToggleButton value={Shipment.Status.PENDING}>Pending</ToggleButton>
          <ToggleButton value={Shipment.Status.TRANSIT}>Transit</ToggleButton>
          <ToggleButton value={Shipment.Status.RECEIVED}>Received</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <SupplierTable headers={isPending ? pendingHeaders : headers} data={shipments}>
        {shipment => (
          <>
            <TableCell>{shipment.id}</TableCell>
            <TableCell>{shipment.description}</TableCell>
            <TableCell>{shipment.itemCount}</TableCell>
            {!isPending && <TableCell>{shipment.carrier}</TableCell>}
            {!isPending && (
              <TableCell>
                <Link to={shipment?.trackingLink ?? ''} target='_blank'>
                  <Typography variant='link'>{shipment.trackingNumber}</Typography>
                </Link>
              </TableCell>
            )}
            <TableCell>{shipment.manufacturerDestination}</TableCell>
            <TableCell>
              <Button
                size='small'
                variant='outlined'
                onClick={() => navigate(String(shipment.id))}
                sx={{ textWrap: 'nowrap' }}
              >
                View Details
              </Button>
            </TableCell>
          </>
        )}
      </SupplierTable>
      <Pagination total={totalShipments} pageSizeOptions={[25, 50, 75]} />
    </Stack>
  )
}

const headers = ['ID', 'Description', 'Units', 'Carrier', 'Tracking #', 'Destination', 'Action']
const pendingHeaders = ['ID', 'Description', 'Units', 'Destination', 'Action']
